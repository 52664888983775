<template>
  <v-dialog v-model="show" persistent max-width="600px" min-width="360px">
    <v-app-bar dense color="blue">
      <v-toolbar-title class="white--text">Register</v-toolbar-title>
    </v-app-bar>
    <div>
      <v-card class="px-4">
        <v-card-text>
          <v-form ref="registerForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="name"
                  :rules="[rules.required]"
                  label="Full Name"
                  maxlength="20"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="mobile"
                  :rules="[rules.required]"
                  label="Mobile Number"
                  maxlength="7"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  block
                  v-model="verify"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, passwordMatch]"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  label="Confirm Password"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                <v-btn
                  x-large
                  block
                  :disabled="!valid"
                  color="success"
                  @click="register"
                >
                  Register
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      showPassword: false,
      name: "",
      email: "",
      password: "",
      mobile: "",
      verify: "",
      valid: false,
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      loading: false,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length > 8) || "Password must morre than 8 characters",
        (v) =>
          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
            v
          ) ||
          "Password must contain at least 1 Special Character, 1 Numeric and 1 Alpha",
      ],
    };
  },
  methods: {
    register() {
      if (this.$refs.registerForm.validate()) {
        this.submit();
      }
    },
    submit() {
      this.loading = true;
      let self = this;
      let f = {
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        password: this.password,
        verify: this.verify,
      };
      this.api("client/auth/register", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            localStorage.setItem(
              "er_client_user",
              JSON.stringify(response.user)
            );
            localStorage.setItem("er_client_token", response.token);
            document.location.href = "/sign-in";
          } else if (
            response.error ==
            "This is your first login attempt, you need to change your password."
          ) {
            localStorage.setItem("er_client__email", this.email);
            this.$router.push("/change-password");
          } else {
            self.showError(response.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          self.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  name: "Register",
};
</script>
